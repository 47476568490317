import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";
function MenuAllergensModal(props) {
  const { show, handleClose } = props;
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Body className="p-0 text-center">
        <Card className="border-0 h-product-card">
          <Card.Img
            variant="top"
            src="https://awjftp.blob.core.windows.net/awjcontents/digimenuAllergens/Awani_Allergens.png"
          />
          <Card.Body>
            <Card.Title className="fw-bold">
              {/* {language === "AR" ? "مسببات الحساسية" : "Allergens"} */}
              Allergens / مسببات الحساسية
            </Card.Title>
            <Card.Text className="lh-sm fs-allergens">
              {/* {language === "AR"
                ? "يرجى ملاحظة أن الطعام المحضر هنا قد يحتوي على مواد مسببة للحساسية. لا تتردد في التحدث مع أحد أعضاء فريقنا إذا كنت بحاجة إلى معلومات بخصوص مسببات حساسية محددة."
                : "Please note that the food prepared here may contain allergens. Feel free to speak with a member of our team if you need information regarding specific allergens."} */}
                Please note that the food prepared here may contain allergens. Feel free to speak with a member of our team if you need information regarding specific allergens.<br/><br/>
                يرجى ملاحظة أن الطعام المحضر هنا قد يحتوي على مواد مسببة للحساسية. لا تتردد في التحدث مع أحد أعضاء فريقنا إذا كنت بحاجة إلى معلومات بخصوص مسببات حساسية محددة
            </Card.Text>
          </Card.Body>
          <Card.Footer>
            <div className="row justify-content-between pe-3 py-3"></div>
          </Card.Footer>
        </Card>
      </Modal.Body>
    </Modal>
  );
}

export default MenuAllergensModal;
