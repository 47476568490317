import React, { useState, useEffect, useRef } from 'react'
import Placeholder from 'react-bootstrap/Placeholder'
import './design'
import './scripts'
import BANNER from './components/banner'
import BODY from './components/body'
function App() {
  const [language, setLanguage] = useState("EN")
  const [currency, setCurrency] = useState(["AED", "درهم"])
  const [direction, setDirection] = useState("ltr")
  const [activeTab, setActiveTab] = useState()
  const [filteredSubCategories, setFilteredSubCategories] = useState([])
  const dataRefGen = useRef([])
  const [loadingCategories, setLoadingCategories] = useState(true)
  const dataRefCat = useRef([])
  const [loadingSubCategories, setLoadingSubCategories] = useState(true)
  const dataRefSubCat = useRef([])
  const [loadingProducts, setLoadingProducts] = useState(true)
  const dataRefProducts = useRef([])
  const dataRefProductsAr = useRef([])

  function handleLanguage(lang) {
    setLanguage(lang)
    if (lang === "AR") setDirection("rtl")
    else setDirection("ltr")
  }

  useEffect(() => {
    var url_string = window.location.href
    var url = new URL(url_string)
    var countryurl = url.searchParams.get("cnt")
    var lang = url.searchParams.get("lang");
    var slugurl = url.searchParams.get("slug")
    if(lang) setLanguage(lang)

    switch (countryurl) {
      case "KSA":
        setCurrency(["SAR", "ريال"])
        break;
      default:
        setCurrency(["AED", "درهم"])
        break;
    }

    fetch('https://qrportalbackend.azurewebsites.net/generation/outlet/' + slugurl)
      .then(response => response.json())
      .then(data => {
        dataRefGen.current = data
        const slugGeneration = dataRefGen.current[0]['Generation']
        fetch('https://qrportalbackend.azurewebsites.net/category/outlet/lvl1/' + slugGeneration)
          .then(response => response.json())
          .then(data => {
            dataRefCat.current = data
            setLoadingCategories(false)
          })
          .catch(error => console.log(error))
        fetch('https://qrportalbackend.azurewebsites.net/category/outlet/lvl2/' + slugGeneration)
          .then(response => response.json())
          .then(data1 => {
            dataRefSubCat.current = data1
            setLoadingSubCategories(false)
          })
          .catch(error => console.log(error))
        fetch('https://qrportalbackend.azurewebsites.net/menu/outlet/' + slugGeneration + '/1')
          .then(response => response.json())
          .then(data2 => {
            dataRefProducts.current = data2
            fetch('https://qrportalbackend.azurewebsites.net/menu/outlet/' + slugGeneration + '/2')
              .then(response => response.json())
              .then(data2Ar => {
                dataRefProductsAr.current = data2Ar
                setLoadingProducts(false)
                setActiveTab(dataRefCat.current[0].Id)
                  const subcats = dataRefSubCat.current.filter(item => item.ParentCategoryID === dataRefCat.current[0].Id)
                  setFilteredSubCategories(subcats);
              })
              .catch(error => console.log(error))
          })
          .catch(error => console.log(error))
      })
      .catch(error => console.log(error))
    // eslint-disable-next-line
  }, []);

  function handleNav1Click(catId) {
    setActiveTab(catId)
    if (catId ===927 || catId ===958 || catId ===1079){
       window.location = "https://awjftp.blob.core.windows.net/awjcontents/websites/awani/Awani_ShishaMenu_KSA.pdf"
    } 
    if (catId === 191 || catId ===830){
      window.location = "https://awjftp.blob.core.windows.net/awjcontents/QRMenuImage/awani/AwaniShisha2.pdf"
    } 
    if (catId === 715 || catId ===989 || catId ===1240){
      window.location = "https://awjftp.blob.core.windows.net/awjcontents/QRMenuImage/awani/AwaniShishadmm1.pdf"
    } 
    else {
      const subcats = dataRefSubCat.current.filter(item => item.ParentCategoryID === catId);
      setFilteredSubCategories(subcats);
    }
  }

  return (
    <div className="App">
      <BANNER handleLanguage={handleLanguage} language={language} direction={direction} />
      {!loadingProducts ? (<BODY activeTab={activeTab} direction={direction} categories={dataRefCat.current} loadingCategories={loadingCategories} subCategories={filteredSubCategories} loadingSubCategories={loadingSubCategories} products={dataRefProducts.current} productsAr={dataRefProductsAr.current} loadingProducts={loadingProducts} handleNav1Click={handleNav1Click} language={language} currency={currency}/>) : (<Placeholder as="p" animation="glow"><Placeholder className="vh-100" xs={12} size="lg" /></Placeholder>)}
    </div>
  );
}

export default App;
